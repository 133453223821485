import type { I18nOptions } from 'vue-i18n'

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  'es-CO': {
    'month-year': {
      year: 'numeric',
      month: 'short'
    }
  },
  'es-MX': {
    'month-year': {
      year: 'numeric',
      month: 'short'
    }
  }
}

export default datetimeFormats
