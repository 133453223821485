const defaultNumberFormat = {
  style: 'currency',
  currencyDisplay: 'narrowSymbol'
}

const fractionDigitis = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}

export default {
  'es-MX': {
    currency: {
      ...defaultNumberFormat,
      ...fractionDigitis,
      currency: 'MXN'
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  'es-CO': {
    currency: {
      ...defaultNumberFormat,
      ...fractionDigitis,
      currency: 'COP'
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  'en-US': {
    currency: {
      ...defaultNumberFormat,
      ...fractionDigitis,
      currency: 'USD'
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  }
}
