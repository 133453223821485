import numberFormats from './config/number-formats'
import datetimeFormats from './config/datetimeFormats'

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: {
    co: ['es-CO'],
    mx: ['es-MX']
  },
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  numberFormats,
  datetimeFormats
}))
